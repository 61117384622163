import { useMemo } from "react";
import PropTypes from "prop-types";

import { Box, Container, Text, Flex, useBreakpointValue, Image, Button } from "@chakra-ui/react";

import formatMileage from "../../helper/mileage";
import formatPrice from "../../helper/price";

import TramovoLogo from "/assets/images/tramovo-logo.svg";

function Vehicle({ data }) {
	const { make, model, trim_level, mileage, mileage_unit, valuation_low, valuation, lookup_url, vehicle } = data;
	const { year, fuel_type, transmission, image_url } = vehicle;

	const title = useMemo(() => `${make} ${model} ${trim_level}`, [make, model, trim_level]);

	const priceRange = useMemo(() => {
		if (!valuation_low || !valuation) return "Price Unavailable";
		return `${formatPrice(valuation_low)} - ${formatPrice(valuation)}`;
	}, [valuation_low, valuation]);

	const vehicleDetails = useMemo(() => {
		const details = [];
		if (year) details.push(year);
		if (mileage) details.push(formatMileage(mileage, mileage_unit));
		if (fuel_type) details.push(fuel_type);
		if (transmission) details.push(transmission);
		return details.join(" · ").trim();
	}, [fuel_type, mileage, mileage_unit, transmission, year]);

	// Responsive styles
	const formBodyPadding = useBreakpointValue({
		base: { top: "40px", left: "20px", right: "20px", bottom: "40px" },
		md: { top: "40px", left: "54px", right: "54px", bottom: "53px" }
	});

	return (
		<Box flex="1" display="flex" alignItems="center" justifyContent="center">
			<Container maxW="600" p={0}>
				{/* Form Header */}
				<Flex w="full" h={{ base: "64px", md: "80px" }} bg="brand.400" borderBottom="1px" borderColor="brand.300" justifyContent="center" alignItems="center">
					<Text color="brand.700" fontWeight="bold" fontSize={{ base: 18, md: 24 }}>
						Vehicle Valuation
					</Text>
				</Flex>

				{/* Form Body */}
				<Box
					w="full"
					bg="brand.200"
					backdropFilter="blur(10px)"
					boxShadow="xl"
					border="1px"
					borderColor="whiteAlpha.100"
					position="relative"
					pt={formBodyPadding.top}
					pl={formBodyPadding.left}
					pr={formBodyPadding.right}
					pb={formBodyPadding.bottom}
				>
					<Flex direction="column" alignItems="center">
						<Text color="brand.700" fontWeight="bold" fontSize={{ base: 18, md: 22 }} textAlign="center">
							{title}
						</Text>

						<Text color="brand.700" fontWeight="400" fontSize={{ base: 16, md: 18 }} mt={{ base: "15px", md: "20px" }}>
							{vehicleDetails}
						</Text>

						<Image w="full" src={image_url} alt={title} mt={{ base: "22px", md: "40px" }} minH={"150px"} />

						<Text color="brand.700" fontWeight="400" fontSize={{ base: 16, md: 18 }} mt={{ base: "22px", md: "30px" }}>
							Estimated Trade Price:
						</Text>

						<Text color="brand.700" fontWeight="bold" fontSize={{ base: 18, md: 22 }} mt={{ base: "15px", md: "20px" }}>
							{priceRange}
						</Text>

						<Flex w="full" justifyContent="center" mt={{ base: "25px", md: "30px" }}>
							<a href={lookup_url} target="_blank" display="block" rel="noreferrer">
								<Button
									w={{ base: "full", md: "360px" }}
									h="50px"
									rounded={0}
									fontSize={{ base: 16, md: 18 }}
									fontWeight="400"
									bg={"brand.600"}
									color={"brand.500"}
									_hover={{ bg: "brand.600" }}
									type="button"
								>
									View Vehicle Details
								</Button>
							</a>
						</Flex>

						<Flex w="full" justifyContent="center" mt={{ base: "25px", md: "30px" }}>
							<a href="." target="_blank" display="block" rel="noreferrer">
								<Button
									w={{ base: "full", md: "360px" }}
									h="50px"
									rounded={0}
									fontSize={{ base: 16, md: 18 }}
									fontWeight="400"
									bg={"brand.600"}
									color={"brand.500"}
									_hover={{ bg: "brand.600" }}
									type="button"
								>
									Scan Another Vehicle
								</Button>
							</a>
						</Flex>
					</Flex>

					{/* Powered By Tramovo */}
					<Flex justifyContent="center" mt={{ base: "29px", md: "56px" }}>
						<Image src={TramovoLogo} w={{ base: "120px", md: "150px" }} alt="Tramovo Logo" />
					</Flex>
				</Box>
			</Container>
		</Box>
	);
}

Vehicle.propTypes = {
	data: PropTypes.object.isRequired
};

export default Vehicle;

import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import App from "./App.jsx";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [SentryBrowser.browserTracingIntegration()],
	tracesSampleRate: 1.0
});

// Theme with brand color
const theme = extendTheme({
	colors: {
		brand: {
			200: "#FFFFFF", // white
			300: "#C9C9C9", // border
			400: "#F3F3F3", // grey background
			450: "#737373", // grey font
			475: "#606060", // light grey font
			500: "#04141D", // dark blue
			600: "#19BFFB", // light blue
			700: "#04080F" // black
		}
	}
});

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</React.StrictMode>
);

export default function formatPrice(price, currency = "EUR", fractionDigit = 0) {
	if (price === undefined || price === null || price < 0) return "PRICE MISSING";

	return price.toLocaleString("en-US", {
		style: "currency",
		currency: currency,
		maximumFractionDigits: fractionDigit,
		minimumFractionDigits: fractionDigit
	});
}

import { Fragment, useState } from "react";
import { ToastContainer } from "react-toastify";

import { Box, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import FindVehicle from "./components/FindVehicle";
import Vehicle from "./components/Vehicle";
import NotFound from "./components/NotFound";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import NevoTradeBidLogo from "/assets/images/nevo-tradebid-logo.svg";

const App = () => {
	const [data, setData] = useState();

	const headerPadding = useBreakpointValue({ base: { top: "81px", bottom: "40px" }, md: { top: "94px", bottom: "61px" } });
	const mainLogoWidth = useBreakpointValue({ base: "250px", sm: "320px", md: "480px" });

	const router = createBrowserRouter([
		{
			// custom path that contains make name
			path: "/:make",
			element: <Box pb={8}>{data ? <Vehicle data={data} /> : <FindVehicle setData={setData} />}</Box>
		},
		{
			path: "404",
			element: <NotFound />
		},
		{
			path: "*",
			element: <NotFound />
		}
	]);

	return (
		<Fragment>
			<Box minH="100vh" color="white" bg="brand.500">
				{/* Header */}
				<Flex justifyContent="center" pt={headerPadding?.top} pb={headerPadding?.bottom}>
					<Image src={NevoTradeBidLogo} alt="Nevo TradeBid Logo" w={mainLogoWidth} />
				</Flex>

				{/* Main Content */}
				<RouterProvider router={router} />
			</Box>

			<ToastContainer />
		</Fragment>
	);
};

export default App;

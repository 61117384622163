import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { captureException } from "@sentry/browser";

import { Box, Container, VStack, Input, Button, Text, Flex, FormControl, FormLabel, FormErrorMessage, FormHelperText, useBreakpointValue, Image } from "@chakra-ui/react";

import TramovoLogo from "/assets/images/tramovo-logo.svg";
import axios from "axios";

function FindVehicle({ setData }) {
	const [tradeInCode, setTradeInCode] = useState("");
	const [workEmail, setWorkEmail] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [codeError, setCodeError] = useState("");

	const location = useLocation();

	// Extract Brand from the URL
	const brand = useMemo(() => location?.pathname?.replaceAll("/", "") || "", [location.pathname]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${import.meta.env.VITE_NEVO_TRADEIN_API_TOKEN}`
		}

		axios
			.post(
				`${import.meta.env.VITE_NEVO_TRADEIN_API_URL}/estimation/get-and-store-lookup`,
				{
					oem_name: brand,
					oem_email: workEmail,
					unique_code: tradeInCode
				},
				{
					headers
				}
			)
			.then(({ data }) => {
				if (!data) throw new Error("No data received");
				setData(data);
				toast.success("Vehicle retrieved successfully.");
			})
			.catch((error) => {
				const status = error?.response?.status || 500;

				switch (status) {
					case 400:
						toast.error("Invalid data provided. Please check the customer code and email.");
						break;
					case 401:
						toast.error("Unauthorized. Please reach out to Nevo Trade-In support.");
						break;
					case 404:
						toast.error("Vehicle not found. Please check the customer code and email.");
						break;
					default:
						toast.error("An error occurred. Please try again later.");
						captureException(error);
						break;
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const validateCode = (code) => {
		return code.length === 6;
	};

	const handleEmailChange = (e) => {
		const email = e.target.value;
		setWorkEmail(email);
		if (!validateEmail(email) && email !== "") {
			setEmailError("Please enter a valid company address");
		} else {
			setEmailError("");
		}
	};

	const handleCodeChange = (e) => {
		// only alphanumeric characters
		const code = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
		setTradeInCode(code);
		if (!validateCode(code) && code !== "") {
			setCodeError("Please enter a valid six-digit customer code");
		} else {
			setCodeError("");
		}
	};

	const isFormValid = useMemo(() => tradeInCode.length === 6 && validateEmail(workEmail), [tradeInCode, workEmail]);

	// Responsive styles
	const formBodyPadding = useBreakpointValue({
		base: { top: "40px", left: "20px", right: "20px", bottom: "40px" },
		md: { top: "40px", left: "60px", right: "60px", bottom: "53px" }
	});

	return (
		<Box flex="1" display="flex" alignItems="center" justifyContent="center">
			<Container maxW="600" p={0}>
				{/* Form Header */}
				<Flex w="full" h={{ base: "64px", md: "80px" }} bg="brand.400" borderBottom="1px" borderColor="brand.300" justifyContent="center" alignItems="center">
					<Text color="brand.700" fontWeight="bold" fontSize={{ base: 18, md: 24 }}>
						Find Vehicle
					</Text>
				</Flex>

				{/* Form Body */}
				<Box
					w="full"
					bg="brand.200"
					backdropFilter="blur(10px)"
					boxShadow="xl"
					border="1px"
					borderColor="whiteAlpha.100"
					position="relative"
					pt={formBodyPadding.top}
					pl={formBodyPadding.left}
					pr={formBodyPadding.right}
					pb={formBodyPadding.bottom}
				>
					<VStack spacing={4}>
						<form onSubmit={handleSubmit} style={{ width: "100%" }}>
							<VStack spacing={{ base: "58px", md: "56px" }} w="full">
								{/* Customer Code Input */}
								<FormControl isInvalid={!!codeError}>
									<FormLabel fontSize={{ base: 16, md: 18 }} fontWeight="400" color="brand.450">
										Customer Code
									</FormLabel>

									<Input
										value={tradeInCode}
										onChange={handleCodeChange}
										maxLength={6}
										placeholder="Y2K242"
										variant="flushed"
										size="md"
										fontSize={{ base: 16, md: 18 }}
										color="brand.500"
										_focus={{ borderColor: "brand.600" }}
										_hover={{ borderColor: "brand.600" }}
									/>

									{codeError ? (
										<FormErrorMessage fontSize={{ base: 11, md: 16 }}>{codeError}</FormErrorMessage>
									) : (
										<FormHelperText fontSize={{ base: 11, md: 16 }} color="brand.475" fontWeight="400" fontStyle="italic">
											Please enter the six-digit code provided by the customer.
										</FormHelperText>
									)}
								</FormControl>

								{/* Company Email Input */}
								<FormControl isInvalid={!!emailError}>
									<FormLabel fontSize={{ base: 16, md: 18 }} fontWeight="400" color="brand.450">
										Company Email
									</FormLabel>

									<Input
										value={workEmail}
										onChange={handleEmailChange}
										type="email"
										placeholder="johndoe@yourcompany.com"
										variant="flushed"
										size="md"
										fontSize={{ base: 16, md: 18 }}
										color="brand.500"
										_focus={{ borderColor: "brand.600" }}
										_hover={{ borderColor: "brand.600" }}
									/>

									{emailError ? (
										<FormErrorMessage fontSize={{ base: 11, md: 16 }}>{emailError}</FormErrorMessage>
									) : (
										<FormHelperText fontSize={{ base: 11, md: 16 }} color="brand.475" fontWeight="400" fontStyle="italic">
											Please enter your company email address (e.g. john@brand.ie).
										</FormHelperText>
									)}
								</FormControl>
							</VStack>

							<Flex w="full" justifyContent="center" mt={{ base: "40px", md: "56px" }}>
								<Button
									w={{ base: "full", md: "360px" }}
									h="50px"
									rounded={0}
									fontSize={{ base: 16, md: 18 }}
									fontWeight="400"
									bg={!isFormValid ? "gray.700" : "brand.600"}
									color={!isFormValid ? "gray.400" : "brand.500"}
									_hover={{ bg: !isFormValid ? "gray.700" : "brand.600" }}
									isDisabled={!isFormValid}
									isLoading={isLoading}
									loadingText="Confirming..."
									type="submit"
								>
									Confirm
								</Button>
							</Flex>
						</form>
					</VStack>

					{/* Powered By Tramovo */}
					<Flex justifyContent="center" mt={{ base: "29px", md: "56px" }}>
						<Image src={TramovoLogo} w={{ base: "120px", md: "150px" }} alt="Tramovo Logo" />
					</Flex>
				</Box>
			</Container>
		</Box>
	);
}

FindVehicle.propTypes = {
	setData: PropTypes.func.isRequired
};

export default FindVehicle;

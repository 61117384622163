/**
 * @description Formats the mileage of a vehicle, removing any non-numeric characters and adding commas to the number
 * @param mileage - mileage of the vehicle
 * @returns formatted mileage or empty string if mileage is not provided
 */
export default function formatMileage(mileage, mileageUnit) {
	if (!mileage) return null;

	const onlyNumbers = mileage.toString().replace(/[^0-9]/g, "");

	const formattedMileage = onlyNumbers ? parseInt(onlyNumbers).toLocaleString() : onlyNumbers;
	return `${formattedMileage} ${formatMileageUnit(mileageUnit)}`;
}

export function formatMileageUnit(mileageUnit) {
	return mileageUnit === "MILES" ? "Miles" : "KM";
}

import { Box, Container, Text, Flex, useBreakpointValue, Image } from "@chakra-ui/react";

import TramovoLogo from "/assets/images/tramovo-logo.svg";

export default function NotFound() {
	// Responsive styles
	const formBodyPadding = useBreakpointValue({
		base: { top: "40px", left: "20px", right: "20px", bottom: "40px" },
		md: { top: "40px", left: "60px", right: "60px", bottom: "53px" }
	});

	return (
		<Box flex="1" display="flex" alignItems="center" justifyContent="center">
			<Container maxW="600" p={0}>
				{/* Form Header */}
				<Flex w="full" h={{ base: "64px", md: "80px" }} bg="brand.400" borderBottom="1px" borderColor="brand.300" justifyContent="center" alignItems="center">
					<Text color="brand.700" fontWeight="bold" fontSize={{ base: 18, md: 24 }}>
						Not Found
					</Text>
				</Flex>

				{/* Form Body */}
				<Box
					w="full"
					bg="brand.200"
					backdropFilter="blur(10px)"
					boxShadow="xl"
					border="1px"
					borderColor="whiteAlpha.100"
					position="relative"
					pt={formBodyPadding.top}
					pl={formBodyPadding.left}
					pr={formBodyPadding.right}
					pb={formBodyPadding.bottom}
				>
					<Text color="brand.700" fontSize={{ base: 16, md: 20 }} textAlign="center">
						Please reach out to Nevo TradeBid support team.
					</Text>
					{/* Powered By Tramovo */}
					<Flex justifyContent="center" mt={{ base: "29px", md: "56px" }}>
						<Image src={TramovoLogo} w={{ base: "120px", md: "150px" }} alt="Tramovo Logo" />
					</Flex>
				</Box>
			</Container>
		</Box>
	);
}
